import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CardReader } from '../../types/CardReader';
import { GlobalState, Invoice } from '../../types';
import { InvoiceStatuses } from '../../constants/invoice';
import { ModalsConstants } from '../../constants/modals';
import { getAppSettingsPublic } from '../../actions/public';
import { openModal } from '../../actions/modals';
import { showErrorAlert } from '../../actions/alerts';
import { Log } from '../../services/LoggerService';
import { getInvoice, markInvoiceStatusProcessing, unmarkInvoiceStatusProcessing } from '../../services/api/invoices';
import { InvoicePaths } from '../../services/app/paths';
import { cancelPayinDevice, getCardReaders, payinDevice, simualteDevice } from '../../services/api/cardReaders';
import { post } from '../../services/api/http';
import { Dialog, DialogContent, Typography, Grid, Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { LargeButton } from '../ui/buttons/LargeButton';
import { LargePrimaryButton, LargeSecondaryButton } from '../ui/Buttons';
import SuccessOutlined from '../ui/SuccessOutlined';
import LoaderIndicator from '../ui/LoaderIndicator';
import TextButton from '../ui/buttons/TextButton';
import FailureOutlined from '../ui/FailureOutlined';
import { RainforestPayinConfigResponse } from '../../containers/invoice/preparation/steps/RainforestCardDetails';
import ReadyForCard from './ReadyForCard';
import useStyles from './DeviceSelectorModal.css';
import PayCancelledModalContent from './PayCancelledModal';
import InvoicePriceUpdatedModal from './InvoicePriceUpdatedModal';
import { Event } from '../../types';
import { Loader, P } from '@roadsync/roadsync-ui';

export enum CardReaderRainforestState {
    initializing,
    connecting,
    readyForCard,
    paymentProcessing,
    paymentFailed,
    paymentSucceeded,
    simulateDevice,
    paymentCancelled,
    invoicePriceUpdated,
}

interface Props extends OwnProps { }

interface OwnProps {
    invoice: Invoice & { loading?: boolean } | undefined;
    open?: boolean;
    onClose: () => void;
}

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

const DeviceSelectorModal: React.FC<Props> = React.memo((props: Props) => {
    const { invoice, open, onClose } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [list, setList] = useState<CardReader[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<string>('');
    const [cardReaderState, setCardReaderState] = useState(CardReaderRainforestState.initializing);
    const locationId = 'string' === typeof invoice?.location
        ? invoice.location
        : invoice?.location?.id || invoice?.locationId || '';
    const invoiceId = invoice?.id ?? '';
    const [payinConfigId, setPayinConfigId] = useState<string>('');
    const [shouldCreateDevice, setShouldCreateDevice] = useState<boolean>(false);
    const invoiceStatusTimer = useRef<any>(null);
    const env = useSelector((state: GlobalState) => state.publicAppSettings?.settings?.serverEnvironment);
    const isProduction = env === 'production';
    const [eventSimulateDevice, setEventSimulateDevice] = useState<string>('');
    const [cardSimulateDevice, setCardSimulateDevice] = useState<string>('');
    const [shouldSimulateSuccess, setShouldSimulateSuccess] = useState<boolean>(false);
    const [shouldSimulateFailure, setShouldSimulateFailure] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [manuallyUpdateToCancel, setManuallyUpdateToCancel] = useState<boolean>(false);
    let currentCardReaderStateRef = useRef(cardReaderState);
    const handleCreatePayingDeviceCalled = useRef(false);
    const cancelTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const classes = useStyles();
    const baseId = 'delete-device-modal';

    useEffect(() => {
        const cancelledFromHardware = async () => {
            if (currentCardReaderStateRef.current !== CardReaderRainforestState.invoicePriceUpdated) {
                if (!manuallyUpdateToCancel) {
                    if (cancelTimeoutRef.current) {
                        clearTimeout(cancelTimeoutRef.current);
                    }
                    setCardReaderState(CardReaderRainforestState.paymentCancelled);
                    setLoading(false);
                }
            }
        }
        window.addEventListener(Event.CancelledFromHardware, cancelledFromHardware);
        return () => {
            if (invoiceStatusTimer.current) {
                clearInterval(invoiceStatusTimer.current);
            }
            window.removeEventListener(Event.CancelledFromHardware, cancelledFromHardware);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manuallyUpdateToCancel]);

    useEffect(() => {
        window.addEventListener(Event.InvoiceUpdatedDuringPayment, cancelledFromInvoicePriceUpdating);
        return () => {
            if (invoiceStatusTimer.current) {
                clearInterval(invoiceStatusTimer.current);
            }
            window.removeEventListener(Event.InvoiceUpdatedDuringPayment, cancelledFromInvoicePriceUpdating);
        };
    }, []);

    useEffect(() => {
        if (!env) {
            dispatch(getAppSettingsPublic());
        }
    }, [env, dispatch]);

    useEffect(() => {
        currentCardReaderStateRef.current = cardReaderState;
    }, [cardReaderState]);

    const cancelledFromInvoicePriceUpdating = async () => {
        setCardReaderState(CardReaderRainforestState.invoicePriceUpdated);
        setLoading(false);
    }

    const _cancelPayinDevice = async (locationId: string, selectedDevice: string) => {
        try {
            await cancelPayinDevice(
                locationId,
                selectedDevice,
            );
            clearInterval(invoiceStatusTimer.current);
        } catch (e) {
            dispatch(showErrorAlert((e as any).message));
            Log.captureException(e as any);
            setLoading(false)
        }
    }

    const handleClose = async () => {
        if (cardReaderState === CardReaderRainforestState.readyForCard) {
            setManuallyUpdateToCancel(false);
            setLoading(true)
            await _cancelPayinDevice(locationId, selectedDevice);
            if (invoice?.token) await unmarkInvoiceStatusProcessing(invoice.token);
            cancelTimeoutRef.current = setTimeout(() => {
                setCardReaderState(CardReaderRainforestState.paymentCancelled);
                setLoading(false);
                setManuallyUpdateToCancel(true);
            }, 5000);

            return;
        } else {
            try {
                if (cardReaderState === CardReaderRainforestState.paymentSucceeded) {
                    history.push(InvoicePaths.listUrl());
                }
                onClose();
            } catch (e) {
                Log.captureException(e as any);
            } finally {
                setCardReaderState(CardReaderRainforestState.initializing);
                setLoading(false);
            }
        }
    }

    const handleDeviceChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDevice(ev.target.value);
    };

    const getPayinConfigId = async (): Promise<void> => {
        try {
            if (invoice?.grandTotal !== '0') {
                const r = await post<RainforestPayinConfigResponse>(`/api/v1/invoices/${invoiceId}/payin/config`, JSON.stringify({
                    billingFirstName: null,
                    billingLastName: null,
                    streetAddress: null,
                    city: null,
                    state: null,
                    zipCode: null,
                }));

                setPayinConfigId(r.data.payin_config_id);
                setShouldCreateDevice(true);
            }
        } catch (e) {
            dispatch(showErrorAlert((e as any).message));
            Log.captureException(e as any);
            setCardReaderState(CardReaderRainforestState.paymentFailed);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const verifyInvoiceStatus = async () => {
        const i = await getInvoice(invoiceId);
        if (i?.status === InvoiceStatuses.COMPLETED.key) {
            setCardReaderState(CardReaderRainforestState.paymentSucceeded);
        }

        if (i?.status === InvoiceStatuses.FAILED.key) {
            setCardReaderState(CardReaderRainforestState.paymentFailed);
        }

        if (i?.status !== InvoiceStatuses.PROCESSING.key) {
            clearInterval(invoiceStatusTimer.current);
        }
    };

    useEffect(() => {
        const handleCreatePayinDevice = async (): Promise<void> => {
            try {
                await payinDevice(
                    locationId,
                    selectedDevice,
                    payinConfigId,
                );
                setCardReaderState(CardReaderRainforestState.readyForCard);

                if (invoice?.token) await markInvoiceStatusProcessing(invoice.token);

                if (invoiceStatusTimer.current) {
                    clearInterval(invoiceStatusTimer.current);
                }
                invoiceStatusTimer.current = setInterval(verifyInvoiceStatus, 2000);

                setShouldCreateDevice(false);
                handleCreatePayingDeviceCalled.current = false;
            } catch (e) {
                dispatch(showErrorAlert((e as any).message));
                Log.captureException(e as any);
                setCardReaderState(CardReaderRainforestState.paymentFailed);
            }
        };

        if (shouldCreateDevice && payinConfigId.length > 0 && !handleCreatePayingDeviceCalled.current) {
            handleCreatePayingDeviceCalled.current = true;
            handleCreatePayinDevice();
        }
    }, [shouldCreateDevice, payinConfigId, dispatch, locationId, selectedDevice, invoice?.token, verifyInvoiceStatus]);

    const handleContinue = async (): Promise<void> => {
        setCardReaderState(CardReaderRainforestState.connecting);
        await getPayinConfigId();
    };

    useEffect(() => {

        setSelectedDevice('');

        if (!locationId) {
            return setList([]);
        }

        setLoading(true);
        getCardReaders(locationId)
            .then((r) => {
                setList(r);
                if (Array.isArray(r) && r.length && !!r[0].DeviceRegistrationId) {
                    setSelectedDevice(r[0].DeviceRegistrationId);
                }
            })
            .catch((e) => {
                dispatch(showErrorAlert((e as any).message));
                Log.captureException(e as any);
            })
            .finally(() => setLoading(false));

    }, [dispatch, locationId]);

    const findDeviceName = (deviceRegistrationId: string) => {
        const cardReader = list?.find(
            (reader) => reader.DeviceRegistrationId === deviceRegistrationId
        );
        return cardReader ? cardReader.DeviceName : 'Device not found';
    }

    const handleSimulateDevice = (): void => {
        setCardReaderState(CardReaderRainforestState.simulateDevice);
    }

    const closeSimulateDevice = (): void => {
        setCardReaderState(CardReaderRainforestState.readyForCard);
    }

    const simualteDeviceInteraction = useCallback(async () => {
        try {
            await simualteDevice(
                locationId,
                selectedDevice,
                eventSimulateDevice,
                cardSimulateDevice,
            );

            setCardReaderState(CardReaderRainforestState.paymentProcessing);
            setShouldSimulateSuccess(false);
        } catch (e) {
            setShouldSimulateFailure(false);
        }

    }, [locationId, selectedDevice, eventSimulateDevice, cardSimulateDevice]);

    const simulateDevicePaymentSuccessful = async (): Promise<void> => {
        setEventSimulateDevice('PRESENTED');
        setCardSimulateDevice('VISA_SUCCESS');
        setShouldSimulateSuccess(true);
    }

    const simulateDevicePaymentFailed = async (): Promise<void> => {
        setEventSimulateDevice('PRESENTED');
        setCardSimulateDevice('INSUFFICIENT_FUNDS');
        setShouldSimulateFailure(true);
    }

    useEffect(() => {
        if (locationId && selectedDevice && shouldSimulateSuccess && eventSimulateDevice && cardSimulateDevice) {
            simualteDeviceInteraction().then(() => {
                setShouldSimulateSuccess(false);
            });
        }

        if (locationId && selectedDevice && shouldSimulateFailure && eventSimulateDevice && cardSimulateDevice) {
            simualteDeviceInteraction().then(() => {
                setShouldSimulateFailure(false);
            });
        }
    }, [locationId, selectedDevice, shouldSimulateSuccess, shouldSimulateFailure, eventSimulateDevice, cardSimulateDevice, simualteDeviceInteraction]);

    const sendReceipt: () => void = (): void => {
        if (invoice?.id) {
            dispatch(openModal(ModalsConstants.SEND_RECEIPT, { invoiceId: invoice.id, payerPhone: invoice.payerPhone }));
        }
        handleClose();
    }

    const tryAgain = (): void => {
        setCardReaderState(CardReaderRainforestState.initializing);
    }

    return (
        <Dialog id={baseId} data-testid={baseId} className={classes.dialog} PaperProps={{ square: false }} fullWidth maxWidth='sm' open={open ?? false}>
            <DialogContent>
                {cardReaderState === CardReaderRainforestState.initializing &&
                    <Grid container direction='column' alignItems='center' spacing={4}>
                        <Grid item>
                            <Typography component='div' variant='h4' className={classes.dialogTitle} id='select-device-title' data-testid='select-device-title'>Select Device</Typography>
                            <Box height={6} />
                        </Grid>
                        <Grid item className={classes.gridItemBtn}>
                            <Grid container direction='column' wrap='nowrap' spacing={2}>
                                <Grid item xs={12}>
                                    {!list.length &&
                                        <P align='center'>Oops! You don't have card readers paired. Please go to your Location settings to add one.</P>
                                    }
                                    {!!list.length &&
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                key='device-field'
                                                id='device-field'
                                                data-testid='device-field'
                                                variant='outlined'
                                                labelId='device-field-label'
                                                onChange={handleDeviceChange as any}
                                                value={selectedDevice}
                                                error={!list.length}
                                            >
                                                {list.map((item, i) => <MenuItem key={i} value={item.DeviceRegistrationId}>{item.DeviceName}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <LargePrimaryButton
                                        type='submit'
                                        onClick={handleContinue}
                                        fullWidth
                                        id='continue-btn'
                                        data-testid='continue-btn'
                                        disabled={!selectedDevice}
                                    >
                                        Continue
                                    </LargePrimaryButton>
                                </Grid>
                                <Grid item xs={12}>
                                    <LargeButton
                                        id='select-cancel-btn'
                                        data-testid='select-cancel-btn'
                                        onClick={handleClose}
                                        fullWidth
                                        variant='outlined'
                                        disableElevation
                                        type='button'
                                    >
                                        Cancel
                                    </LargeButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid><Box height={32} /></Grid>
                    </Grid>
                }
                {cardReaderState === CardReaderRainforestState.connecting &&
                    <Grid container direction='column' alignItems='center' spacing={4}>
                        <Grid item className={classes.gridItemConnecting}>
                            <Box height={90} />
                            <LoaderIndicator id={`${baseId}-loader`} />
                            <Box height={45} />
                            <Box fontSize={16}>Connecting to <Box component='span' fontWeight='700'>{findDeviceName(selectedDevice)}</Box></Box>
                            <Box height={10} />
                        </Grid>
                        <Grid><Box height={32} /></Grid>
                    </Grid>
                }
                {/* container with animation - to make circles opened use styles to hide this portion */}
                <Grid container direction='column' alignItems='center' spacing={2}
                    style={{
                        opacity: `${cardReaderState === CardReaderRainforestState.readyForCard ? '1' : '0'}`,
                        height: `${cardReaderState === CardReaderRainforestState.readyForCard ? 'initial' : '0'}`
                    }}>
                    <Box height={90} />
                    <Grid item>
                        <ReadyForCard />
                        <Box height={80} />
                    </Grid>
                    <Grid item>
                        <Box fontSize={16} textAlign='center' fontWeight='700' mb='20px'>Insert / Swipe / Tap Card</Box>
                        <Box fontSize={16} textAlign='center' mb='12px'>Connected to <Box component='span' fontWeight='700'>{findDeviceName(selectedDevice)}</Box></Box>
                        <Box height={10} />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItemBtn}>
                        <LargeButton
                            id='ready-cancel-btn'
                            data-testid='ready-cancel-btn'
                            onClick={handleClose}
                            fullWidth
                            variant='outlined'
                            disableElevation
                            type='button'
                        >
                            Cancel
                        </LargeButton>
                    </Grid>
                    {!isProduction &&
                        <Grid item xs={12} className={classes.gridItemBtn}>
                            <LargePrimaryButton
                                type='submit'
                                onClick={handleSimulateDevice}
                                fullWidth
                                id='continue-btn'
                                data-testid='continue-btn'
                            >
                                Simulate device interaction
                            </LargePrimaryButton>
                        </Grid>
                    }
                    <Grid><Box height={32} /></Grid>
                </Grid>
                {/* end container with animation */}
                {cardReaderState === CardReaderRainforestState.simulateDevice &&
                    <Grid container direction='column' alignItems='center' spacing={2}>
                        <Box height={32} />
                        <Grid item xs={12} className={classes.gridItemBtn}>
                            <LargePrimaryButton
                                onClick={simulateDevicePaymentSuccessful}
                                fullWidth
                                id='simulate-payment-successful-btn'
                                data-testid='simulate-payment-successful-btn'
                            >
                                Simulate payment successful
                            </LargePrimaryButton>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItemBtn}>
                            <LargePrimaryButton
                                onClick={simulateDevicePaymentFailed}
                                fullWidth
                                id='simulate-payment-failed-btn'
                                data-testid='simulate-payment-failed-btn'
                            >
                                Simulate payment failed
                            </LargePrimaryButton>
                        </Grid>
                        <Grid item xs={12} className={classes.gridItemBtn}>
                            <LargeButton
                                id='close-simulate-device-btn'
                                data-testid='close-simulate-device-btn'
                                onClick={closeSimulateDevice}
                                fullWidth
                                variant='outlined'
                                disableElevation
                                type='button'
                            >
                                Cancel
                            </LargeButton>
                        </Grid>
                        <Grid><Box height={32} /></Grid>
                    </Grid>
                }
                {cardReaderState === CardReaderRainforestState.paymentProcessing &&
                    <Grid container direction='column' alignItems='center' spacing={4}>
                        <Box height={154} />
                        <Grid item>
                            <LoaderIndicator id={`${baseId}-loader`} />
                            <Box height={154} />
                        </Grid>
                    </Grid>
                }
                {cardReaderState === CardReaderRainforestState.paymentSucceeded &&
                    <Grid container direction='column' alignItems='center' spacing={4}>
                        <Box height={40} />
                        <Grid item>
                            <SuccessOutlined id={`${baseId}-success-icon`} />
                        </Grid>
                        <Grid item>
                            <Box fontSize={16} textAlign='center' fontWeight='700' mb='20px'>Payment Successful</Box>
                            <Box fontSize={16} textAlign='center' mb='12px'>Connected to <Box component='span' fontWeight='700'>{findDeviceName(selectedDevice)}</Box></Box>
                        </Grid>
                        <Grid item className={classes.gridItemBtn}>
                            <LargeSecondaryButton
                                id={`${baseId}-send-receipt-btn`}
                                data-testid={`${baseId}-send-receipt-btn`}
                                onClick={sendReceipt}
                                variant='outlined'
                                fullWidth
                            >
                                Send Invoice Receipt
                            </LargeSecondaryButton>
                        </Grid>
                        <Grid item className={classes.gridItemBtn}>
                            <TextButton
                                id={`${baseId}-back-to-invoice-list-btn`}
                                onClick={handleClose}
                                fullWidth
                            >
                                Back to invoice list
                            </TextButton>
                            <Box height={20} />
                        </Grid>
                    </Grid>
                }
                {cardReaderState === CardReaderRainforestState.paymentFailed &&
                    <Grid container direction='column' alignItems='center' spacing={4}>
                        <Box height={40} />
                        <Grid item>
                            <FailureOutlined id={`${baseId}-failure-icon`} />
                        </Grid>
                        <Grid item>
                            <Box fontSize={16} textAlign='center' fontWeight='700' mb='20px'>Payment Failed</Box>
                            <Box fontSize={16} textAlign='center' mb='12px'>Connected to <Box component='span' fontWeight='700'>{findDeviceName(selectedDevice)}</Box></Box>
                        </Grid>
                        <Grid item className={classes.gridItemBtn}>
                            <LargeSecondaryButton
                                id={`${baseId}-try-again-btn`}
                                data-testid={`${baseId}-try-again-btn`}
                                onClick={tryAgain}
                                variant='outlined'
                                fullWidth
                            >
                                Try Again
                            </LargeSecondaryButton>
                        </Grid>
                        <Grid item className={classes.gridItemBtn}>
                            <TextButton
                                id={`${baseId}-failed-cancel-btn`}
                                onClick={handleClose}
                                fullWidth
                            >
                                Cancel
                            </TextButton>
                            <Box height={20} />
                        </Grid>
                    </Grid>
                }
                {cardReaderState === CardReaderRainforestState.paymentCancelled &&
                    <PayCancelledModalContent
                        handleCancel={handleClose}
                        handleTryAgain={tryAgain}
                        deviceName={findDeviceName(selectedDevice)}
                    />
                }
                {cardReaderState === CardReaderRainforestState.invoicePriceUpdated &&
                    <InvoicePriceUpdatedModal
                        handleCancel={handleClose}
                        handleTryAgain={tryAgain}
                        deviceName={findDeviceName(selectedDevice)}
                    />
                }
                <Loader show={loading} />
            </DialogContent >
        </Dialog >
    );
}, areEqual);

export default DeviceSelectorModal;
