import _ from 'lodash';
import { FeatureCategories } from './featuresCategories';

export interface FeatureType {
    key: string;
    display: string;
    category: string;
}

export class FeatureTypes {
    static readonly CASH: FeatureType = {
        key: 'cash',
        display: 'Cash',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly CARDS: FeatureType = {
        key: 'cards',
        display: 'Cards',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly DIRECT_BILL: FeatureType = {
        key: 'directbill',
        display: 'Direct Bill',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly HOST_BILL: FeatureType = {
        key: 'hostbill',
        display: 'Host Bill',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly PAYCODES: FeatureType = {
        key: 'paycodes',
        display: 'Paycodes',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly PUBLIC_CHECKOUT: FeatureType = {
        key: 'publiccheckout',
        display: 'Enable Remote Checkout',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };
    static readonly ACH_PAYMENT: FeatureType = {
        key: 'achpayment',
        display: 'ACH',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly MONTHLY_FEE: FeatureType = {
        key: 'monthlyfee',
        display: 'Monthly fee',
        category: FeatureCategories.FEE.key,
    };

    static readonly FEE_PER_PRODUCT: FeatureType = {
        key: 'feeperproduct',
        display: 'Subtract fee from line items',
        category: FeatureCategories.FEE.key,
    };

    static readonly ENABLE_PRODUCTS_COMMENT: FeatureType = {
        key: 'enableproductscomment',
        display: 'Enable Products Comment',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly INVOICE_ATTACHMENT: FeatureType = {
        key: 'invoiceattachment',
        display: 'Invoice Attachments',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly DISABLE_INVOICES: FeatureType = {
        key: 'disableinvoices',
        display: 'Disable Invoices',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly EXPEDITED_PAYOUT: FeatureType = {
        key: 'expeditedpayout',
        display: 'Expedited Payout (2 day settlement)',
        category: FeatureCategories.PAYOUT.key,
    };

    static readonly RECEIPT_NOTIFICATIONS: FeatureType = {
        key: 'rcptnotifs',
        display: 'Receipt Notifications',
        category: FeatureCategories.RECEIPT.key,
    };

    static readonly SIGNATURE_CAPTURE: FeatureType = {
        key: 'signaturecapture',
        display: 'Signature capture',
        category: FeatureCategories.SIGNATURE.key,
    };

    static readonly ENABLE_WORK_ORDERS: FeatureType = {
        key: 'enableworkorders',
        display: 'Enable Work Orders',
        category: FeatureCategories.WORK_ORDERS.key,
    };

    static readonly PAYOUT_AMOUNTS_IN_EMAIL: FeatureType = {
        key: 'payoutamountsinemail',
        display: 'Use a payout amount in an email body',
        category: FeatureCategories.EMAILS.key,
    };

    static readonly ZERO_AMOUNTS_IN_EMAIL: FeatureType = {
        key: 'zeroamountsinemail',
        display: 'Show zero dollar invoice amounts in email body',
        category: FeatureCategories.EMAILS.key,
    };

    static readonly REPORT_DOLLAR_SIGN_DISABLED: FeatureType = {
        key: 'reportdollarsigndisabled',
        display: 'Disable Dollar Sign on Reports',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly ALLOW_DISABLE_CONV_FEE: FeatureType = {
        key: 'allowdisableconvfee',
        display: 'Allow for convenience fee to be disabled at a per invoice basis',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly INVOICE_CSV_TIMESTAMP_ENABLED: FeatureType = {
        key: 'invoicecsvtimestampenabled',
        display: 'Show a timestamp column in the invoices report csv',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly SURVEY: FeatureType = {
        key: 'survey',
        display: 'Survey',
        category: FeatureCategories.SURVEY.key,
    };

    static readonly COMPANY_DASHBOARD: FeatureType = {
        key: 'companydashboard',
        display: 'Company Dashboard',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly REPORT_BASEAMT_CONVFEE: FeatureType = {
        key: 'reportbaseamtconvfee',
        display: 'Always show Base Amount and Convenience Fee',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly REPORT_TAXTOTAL_PERCENT: FeatureType = {
        key: 'reporttaxtotalpercent',
        display: 'Show tax total and tax percentage on invoice and deposit csv reports',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly REPORT_CONVFEE_MARKUP: FeatureType = {
        key: 'reportconvfeemarkup',
        display: 'Show convenience fee markup on invoice and deposit csv reports',
        category: FeatureCategories.REPORTS.key,
    };

    static readonly TRANSACTION_REPORT_ALL_PRODUCTS: FeatureType = {
        key: "transactionreportallproducts",
        display: "Include all products in Transaction Report csv",
        category: FeatureCategories.REPORTS.key,
    };

    static readonly REPORT_IS_REMOTE_CHECKOUT: FeatureType = {
        key: "reportisremotecheckout",
        display: "Show if invoice was paid via remote checkout",
        category: FeatureCategories.REPORTS.key,
    };

    static readonly ADD_MULTIPLE_LINE_ITEMS: FeatureType = {
        key: 'addmultiplelineitems',
        display: 'Add multiple line items',
        category: FeatureCategories.PRODUCTS.key,
    };

    static readonly ACCOUNTING_QUICKBOOKS: FeatureType = {
        key: 'accountingquickbooks',
        display: 'Accounting QuickBooks',
        category: FeatureCategories.ACCOUNTING.key,
    };

    static readonly NAME_REQUIRED_WITH_CARD: FeatureType = {
        key: 'namerequiredwithcard',
        display: 'Require name with card',
        category: FeatureCategories.CARDS.key,
    };

    static readonly LICENSE_REQUIRED_WITH_CARD: FeatureType = {
        key: 'licenserequiredwithcard',
        display: 'Require license with card',
        category: FeatureCategories.CARDS.key,
    };

    static readonly INVOICE_SUBMISSION_CONFIRMATION: FeatureType = {
        key: 'invoicesubmissionconfirmation',
        display: 'Invoice Submission Confirmation',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly ALLOW_ZERO_DOLLAR_INVOICE: FeatureType = {
        key: 'allowzerodollarinvoice',
        display: 'Allow Zero Dollar Invoice',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly NOTIFY_INVOICE_STATUS_CHANGE: FeatureType = {
        key: 'notifyinvoicestatuschange',
        display: 'Notify when Invoice Status Changes',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly PAY_INVOICES: FeatureType = {
        key: 'canpayinvoices',
        display: 'Allow this company to be a payer for invoices',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly ACH: FeatureType = {
        key: 'ach',
        display: 'Bank Account Transfer',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly CREDIT_CARD_AT_PUBLIC_CHECKOUT_ONLY: FeatureType = {
        key: 'creditcardatpubliccheckoutonly',
        display: 'Credit Card At Public Checkout Only',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly WEX: FeatureType = {
        key: 'wexapi',
        display: 'Use WEX API for EFS & TChek / Required for Fleet Cards',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly RAINFOREST_PAY: FeatureType = {
        key: 'rainforestpay',
        display: 'RainForest Pay DO NOT CHANGE',
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly CONVENIENCE_FEE_PER_LINE_ITEM: FeatureType = {
        key: 'conveniencefeeperlineitem',
        display: 'Convenience Fee Per Line Item',
        category: FeatureCategories.FEE.key,
    };

    static readonly SKIP_CHECK_DETAILS_SCREEN: FeatureType = {
        key: 'skipcheckdetailsscreen',
        display: 'Skip Check Details Confirmation Screen',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly REDUCE_PAPER_CHECK_FLOW: FeatureType = {
        key: 'reducepapercheckflow',
        display: 'Reduce Paper Check Flow',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly SEND_TOTAL_TO_PAYER_BUTTON: FeatureType = {
        key: 'sendtotaltopayerbutton',
        display: 'Show Send Total to Payer Button',
        category: FeatureCategories.INVOICE.key,
    };

    static readonly GENERAL_LEDGER: FeatureType = {
        key: 'generalledger',
        display: 'General Ledger (G/L) for Products',
        category: FeatureCategories.PRODUCTS.key,
    };

    static readonly CARD_ON_FILE: FeatureType = {
        key: 'cardonfile',
        display: 'Use Card On File',
        category: FeatureCategories.CARDS.key,
    };

    static readonly INSTANT_PAYOUT: FeatureType = {
        key: "instantpayout",
        display: "Instant Payout",
        category: FeatureCategories.PAYOUT.key,
    }

    static readonly PARTIAL_REFUND: FeatureType = {
        key: "partialrefund",
        display: "Partial Refunds",
        category: FeatureCategories.PAYOUT.key,
    }

    static readonly EMAIL_DISABLED: FeatureType = {
        key: "emaildisabled",
        display: "Disable Emails",
        category: FeatureCategories.EMAILS.key,
    }

    static readonly SMS_DISABLED: FeatureType = {
        key: "smsdisabled",
        display: "Disable SMS",
        category: FeatureCategories.SMS.key,
    }

    static readonly SECURED_BANKING: FeatureType = {
        key: "securedbanking",
        display: "Secured Banking",
        category: FeatureCategories.ACCOUNTING.key,
    }

    static readonly SKIP_MICRO_DEPOSITS: FeatureType = {
        key: "skipmicrodeposits",
        display: "Skip Dwolla Microdeposit Verification",
        category: FeatureCategories.ACCOUNTING.key,
    }

    static readonly USE_COMPANY_NAME_ON_EMAILS: FeatureType = {
        key: "usecompanynameonemail",
        display: "Use company name on email notifications",
        category: FeatureCategories.EMAILS.key,
    };

    static readonly UNPAID_INVOICE_REMINDERS: FeatureType = {
        key: "unpaidinvoicereminders",
        display: "Unpaid invoice reminder",
        category: FeatureCategories.INVOICE.key,
    };

    static readonly RAINFOREST_PAY_3DS: FeatureType = {
        key: "rainforestpay3ds",
        display: "Enable Rainforest Pay 3D Security",
        category: FeatureCategories.CARDS.key,
    };

    static readonly ZIP_BILLING_ADDRESS: FeatureType = {
        key: "cardzipandbillingaddress",
        display: "Zip and Billing Address Required",
        category: FeatureCategories.CARDS.key,
    };

    static readonly TOWBOOK: FeatureType = {
        key: "towbookintegration",
        display: "Towbook Integration",
        category: FeatureCategories.ACCOUNTING.key,
    };

    static readonly ONESOURCE: FeatureType = {
        key: "onesourceintegration",
        display: "ONESOURCE Integration",
        category: FeatureCategories.ACCOUNTING.key,
    };

    static readonly EXPRESS_PAYMENT: FeatureType = {
        key: "expresspayment",
        display: "Express Payment",
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly HOSTED_PAY_PAGE: FeatureType = {
        key: "hostedpaypage",
        display: "Hosted Pay Page",
        category: FeatureCategories.PAYMENT_METHOD.key,
    };

    static readonly NEXT_DAY_SETTLEMENT: FeatureType = {
        key: "nextdaysettlement",
        display: "Next Day Settlement",
        category: FeatureCategories.PAYOUT.key,
    };

    static readonly ADDRESS_BOOK: FeatureType = {
        key: "addressbook",
        display: "Address Book",
        category: FeatureCategories.ACCOUNTING.key,
    };

    static readonly RAINFOREST_PAY_BUTTON: FeatureType = {
        key: "standardrainforestpaybutton",
        display: "Use standard rainforest pay button",
        category: FeatureCategories.INVOICE.key,
    };

    private static readonly LOOKUP = {
        'cash': FeatureTypes.CASH,
        'cards': FeatureTypes.CARDS,
        'publiccheckout': FeatureTypes.PUBLIC_CHECKOUT,
        'achpayment': FeatureTypes.ACH_PAYMENT,
        'monthlyfee': FeatureTypes.MONTHLY_FEE,
        'feeperproduct': FeatureTypes.FEE_PER_PRODUCT,
        'invoiceattachment': FeatureTypes.INVOICE_ATTACHMENT,
        'disableinvoices': FeatureTypes.DISABLE_INVOICES,
        'signaturecapture': FeatureTypes.SIGNATURE_CAPTURE,
        'enableproductscomment': FeatureTypes.ENABLE_PRODUCTS_COMMENT,
        'enableworkorders': FeatureTypes.ENABLE_WORK_ORDERS,
        'rcptnotifs': FeatureTypes.RECEIPT_NOTIFICATIONS,
        'expeditedpayout': FeatureTypes.EXPEDITED_PAYOUT,
        'payoutamountsinemail': FeatureTypes.PAYOUT_AMOUNTS_IN_EMAIL,
        'zeroamountsinemail': FeatureTypes.ZERO_AMOUNTS_IN_EMAIL,
        'reportdollarsigndisabled': FeatureTypes.REPORT_DOLLAR_SIGN_DISABLED,
        'allowdisableconvfee': FeatureTypes.ALLOW_DISABLE_CONV_FEE,
        'invoicecsvtimestampenabled': FeatureTypes.INVOICE_CSV_TIMESTAMP_ENABLED,
        'survey': FeatureTypes.SURVEY,
        'hostbill': FeatureTypes.HOST_BILL,
        'directbill': FeatureTypes.DIRECT_BILL,
        'companydashboard': FeatureTypes.COMPANY_DASHBOARD,
        'addmultiplelineitems': FeatureTypes.ADD_MULTIPLE_LINE_ITEMS,
        'accountingquickbooks': FeatureTypes.ACCOUNTING_QUICKBOOKS,
        'namerequiredwithcard': FeatureTypes.NAME_REQUIRED_WITH_CARD,
        'licenserequiredwithcard': FeatureTypes.LICENSE_REQUIRED_WITH_CARD,
        'invoicesubmissionconfirmation': FeatureTypes.INVOICE_SUBMISSION_CONFIRMATION,
        'allowzerodollarinvoice': FeatureTypes.ALLOW_ZERO_DOLLAR_INVOICE,
        'notifyinvoicestatuschange': FeatureTypes.NOTIFY_INVOICE_STATUS_CHANGE,
        'canpayinvoices': FeatureTypes.PAY_INVOICES,
        'ach': FeatureTypes.ACH,
        'creditcardatpubliccheckoutonly': FeatureTypes.CREDIT_CARD_AT_PUBLIC_CHECKOUT_ONLY,
        'wexapi': FeatureTypes.WEX,
        'rainforestpay': FeatureTypes.RAINFOREST_PAY,
        'conveniencefeeperlineitem': FeatureTypes.CONVENIENCE_FEE_PER_LINE_ITEM,
        'skipcheckdetailsscreen': FeatureTypes.SKIP_CHECK_DETAILS_SCREEN,
        [FeatureTypes.REDUCE_PAPER_CHECK_FLOW.key]: FeatureTypes.REDUCE_PAPER_CHECK_FLOW,
        'sendtotaltopayerbutton': FeatureTypes.SEND_TOTAL_TO_PAYER_BUTTON,
        'generalledger': FeatureTypes.GENERAL_LEDGER,
        'reportbaseamtconvfee': FeatureTypes.REPORT_BASEAMT_CONVFEE,
        'reporttaxtotalpercent': FeatureTypes.REPORT_TAXTOTAL_PERCENT,
        'reportconvfeemarkup': FeatureTypes.REPORT_CONVFEE_MARKUP,
        'cardonfile': FeatureTypes.CARD_ON_FILE,
        'instantpayout': FeatureTypes.INSTANT_PAYOUT,
        [FeatureTypes.PARTIAL_REFUND.key]: FeatureTypes.PARTIAL_REFUND,
        [FeatureTypes.EMAIL_DISABLED.key]: FeatureTypes.EMAIL_DISABLED,
        [FeatureTypes.SMS_DISABLED.key]: FeatureTypes.SMS_DISABLED,
        [FeatureTypes.SECURED_BANKING.key]: FeatureTypes.SECURED_BANKING,
        [FeatureTypes.SKIP_MICRO_DEPOSITS.key]: FeatureTypes.SKIP_MICRO_DEPOSITS,
        [FeatureTypes.TRANSACTION_REPORT_ALL_PRODUCTS.key]: FeatureTypes.TRANSACTION_REPORT_ALL_PRODUCTS,
        [FeatureTypes.USE_COMPANY_NAME_ON_EMAILS.key]: FeatureTypes.USE_COMPANY_NAME_ON_EMAILS,
        [FeatureTypes.UNPAID_INVOICE_REMINDERS.key]: FeatureTypes.UNPAID_INVOICE_REMINDERS,
        [FeatureTypes.RAINFOREST_PAY_3DS.key]: FeatureTypes.RAINFOREST_PAY_3DS,
        [FeatureTypes.ZIP_BILLING_ADDRESS.key]: FeatureTypes.ZIP_BILLING_ADDRESS,
        [FeatureTypes.TOWBOOK.key]: FeatureTypes.TOWBOOK,
        [FeatureTypes.ONESOURCE.key]: FeatureTypes.ONESOURCE,
        [FeatureTypes.EXPRESS_PAYMENT.key]: FeatureTypes.EXPRESS_PAYMENT,
        [FeatureTypes.HOSTED_PAY_PAGE.key]: FeatureTypes.HOSTED_PAY_PAGE,
        [FeatureTypes.NEXT_DAY_SETTLEMENT.key]: FeatureTypes.NEXT_DAY_SETTLEMENT,
        [FeatureTypes.ADDRESS_BOOK.key]: FeatureTypes.ADDRESS_BOOK,
        [FeatureTypes.RAINFOREST_PAY_BUTTON.key]: FeatureTypes.RAINFOREST_PAY_BUTTON,
        [FeatureTypes.REPORT_IS_REMOTE_CHECKOUT.key]: FeatureTypes.REPORT_IS_REMOTE_CHECKOUT,
    };

    static getTypes(): FeatureType[] {
        return _.values(FeatureTypes.LOOKUP);
    }

    static getByKey(key: string): FeatureType {
        return FeatureTypes.LOOKUP[key];
    }

}
